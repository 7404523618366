<template>
  <div class="cart-item pa-3">
    <v-row align="center">
      <v-col
        cols="8"
        :sm="full ? 9 : 6"
        :md="full ? 4 : 6"
        class="d-flex flex-row align-center mr-auto"
        :class="{ 'pl-1': !isCheckout }"
      >
        <img
          :src="item.product.mediaURL"
          :alt="'Immagine prodotto'"
          :class="{ 'mr-3': !isCheckout }"
        />
        <div class="description">
          <div
            class="name text-wrap font-weight-bold"
            :class="{
              'order-1': item.cartItemInfo.SUBSCRIPTION_PLAN_ID
            }"
          >
            {{ item.product.name }}
          </div>
          <div
            class="description"
            :class="{
              'order-0': item.cartItemInfo.SUBSCRIPTION_PLAN_ID
            }"
          >
            {{ item.product.description }}
          </div>
          <div
            class="short-descr"
            v-if="
              showDescrWeight &&
                item.product.productInfos.PRODUCT_NATURE_ID != '10'
            "
          >
            {{ item.product.shortDescr }}
          </div>
          <div
            class="cart-item-info"
            v-if="item.product.productInfos.PRODUCT_NATURE_ID != '10'"
          >
            <em>{{ item.cartItemInfo.size }}</em>
          </div>
        </div>
      </v-col>
      <v-col
        cols="3"
        md="2"
        v-if="
          (item.editable &&
            full &&
            item.product.productInfos.PRODUCT_NATURE_ID != '10') ||
            (full &&
              item.cartItemInfo.SUBSCRIPTION_PLAN_ID &&
              isCheckout &&
              $vuetify.breakpoint.smAndUp)
        "
        class="d-flex flex-column"
      >
        <ProductPromo
          v-if="warehousePromoBody"
          :warehousePromo="product.warehousePromo"
          class="font-weight-bold mb-3"
        />
        <ProductPrice
          v-if="item.editable || item.cartItemInfo.SUBSCRIPTION_PLAN_ID"
          :product="item.product"
          :showDescrWeight="showDescrWeight"
        ></ProductPrice>
      </v-col>
      <!-- PRODOTTO IN OMMAGGIO -->
      <v-col
        cols="4"
        sm="3"
        md="3"
        v-if="item.cartItemInsertionTypeId == 2"
        class="d-flex justify-sm-center order-sm-2"
      >
        <span
          v-html="$t('product.freeProduct')"
          class="cart-item--gift text-uppercase text-center font-weight-bold mx-md-auto"
        ></span
      ></v-col>
      <v-col
        cols="8"
        :sm="full ? 9 : 3"
        :md="item.cartItemInsertionTypeId == 2 && full ? 3 : 3"
        class="d-flex justify-center"
      >
        <div
          class="qty-wrap rounded-pill"
          :class="{ 'not-empty': quantity > 0 }"
          v-if="item.product.productInfos.PRODUCT_NATURE_ID != '10'"
        >
          <a
            role="button"
            class="minus"
            @click.prevent.stop="minus(true, { infos: item.cartItemInfo })"
            @mousedown.stop
            v-if="
              item.editable &&
                item.product.productInfos.PRODUCT_NATURE_ID !== '8' &&
                item.product.productInfos.PRODUCT_NATURE_ID !== '10'
            "
          >
            <v-icon>$minus</v-icon>
          </a>
          <div class="val-cnt" @click.stop.prevent="" @mousedown.stop>
            <span class="val">{{ quantity }} {{ unit }}</span>
            <span class="small">{{ quantityPerUnit }}</span>
          </div>
          <a
            role="button"
            class="plus"
            @click.prevent.stop="plus(true, { infos: item.cartItemInfo })"
            @mousedown.stop
            v-if="
              item.editable &&
                item.product.productInfos.PRODUCT_NATURE_ID !== '8' &&
                item.product.productInfos.PRODUCT_NATURE_ID !== '10'
            "
          >
            <v-icon>$plus</v-icon>
          </a>
        </div>
      </v-col>
      <v-col
        cols="8"
        :sm="full ? 9 : 3"
        md="3"
        class="d-flex justify-center"
        v-if="item.product.productInfos.PRODUCT_NATURE_ID === 'rewards'"
      >
        <div
          class="qty-wrap rounded-pill"
          :class="{ 'not-empty': quantity > 0 }"
        >
          <div class="val-cnt" @click.stop.prevent="" @mousedown.stop>
            <span class="val">{{ quantity }} {{ unit }}</span>
            <span class="small">{{ quantityPerUnit }}</span>
          </div>
        </div>
      </v-col>
      <v-col
        cols="3"
        class="d-flex flex-row align-center justify-center"
        v-if="item.editable || item.cartItemInfo.SUBSCRIPTION_PLAN_ID"
      >
        <div class="gross-total price">
          {{ $n(item.grossTotal, "currency") }}
        </div>

        <v-btn
          v-if="item.editable"
          icon
          class="remove-cart-item ml-1 ml-md-4"
          aria-label="Rimuovi dal carrello"
          @click.prevent="remove"
          @mousedown.stop
        >
          <v-icon>$delete</v-icon>
        </v-btn>
      </v-col>

      <v-col
        v-if="
          item.editable &&
            isCheckout &&
            !hideAcceptSubstitutes &&
            !item.product.warehousePromo &&
            item.product.productInfos.PRODUCT_NATURE_ID != '10'
        "
        cols="12"
        class="pt-0 d-flex flex-column flex-sm-row"
        @click.prevent.stop=""
      >
        <v-checkbox
          v-if="
            item.product.productInfos.PRODUCT_NATURE_ID !== 'rewards' &&
              item.product.productInfos.PRODUCT_NATURE_ID !== '8' &&
              item.product.productInfos.PRODUCT_NATURE_ID !== '10'
          "
          dense
          color="primary"
          hide-details
          class="accept-alternatives"
          v-model="acceptAlternative"
        >
          <template v-slot:label>
            <span>{{ $t("cart.acceptAlternativesSingle") }}</span>
          </template>
        </v-checkbox>
      </v-col>
    </v-row>

    <v-dialog v-model="removeDialog" max-width="400">
      <div class="vuedl-layout__closeBtn" @click="removeDialog = false">
        ×
      </div>
      <v-card>
        <v-card-text class="body-1 pt-8">
          {{
            $t("message.remove", {
              name: this.product.name,
              quantity: this.quantity,
              unit: this.unit
            })
          }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            depressed
            outlined
            color="primary"
            @click="removeDialog = false"
          >
            No
          </v-btn>

          <v-btn
            color="primary"
            depressed
            @click.prevent="doRemove"
            @mousedown.stop
          >
            Si
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped lang="scss">
.cart-item {
  .name,
  .short-desct,
  .description .name {
    word-break: break-all;
  }
  &--gift {
    color: #de1e1e;
    font-family: "Oswald", sans-serif;
    font-size: 1.2rem;
  }
}
img {
  height: 60px;
  width: 60px;
}
:deep(.product-price) .old-price {
  min-height: 0px !important;
}
.actions {
  width: 100%;
}
.accept-alternatives {
  :deep(.v-label) {
    font-size: 12px;
  }
  // &.padding-large {
  //   @media #{map-get($display-breakpoints, 'sm-and-down')} {
  //     padding-left: 12px;
  //   }
  //   @media #{map-get($display-breakpoints, 'md-and-up')} {
  //     padding-left: 120px;
  //   }
  // }
}
:deep(textarea) {
  font-size: 13px !important;
}
</style>

<script>
import productMixin from "~/mixins/product";
import ProductPrice from "@/components/product/ProductPrice.vue";
import ProductPromo from "@/components/product/ProductPromo.vue";
import eurospinCustomService from "@/service/eurospinCustomService";
import { mask } from "vue-the-mask";
import { mapActions, mapGetters } from "vuex";
import get from "lodash/get";

export default {
  name: "CartItem",
  props: {
    item: { type: Object },
    full: { type: Boolean, default: false },
    showDescrWeight: { type: Boolean, default: true },
    hideAcceptSubstitutes: { type: Boolean, default: true },
    isCheckout: { type: Boolean, default: false }
  },
  mixins: [productMixin],
  components: { ProductPrice, ProductPromo },
  directives: {
    mask: mask
  },
  data() {
    return {
      removeDialog: false
      // tempUserNotes: ""
    };
  },
  computed: {
    product() {
      return this.item.product;
    },
    warehousePromoBody() {
      return get(this.product, "warehousePromo.view.body", null);
    },
    packageCount() {
      if (this.product.productInfos.TIPOLOGIA != "Sfuso" && this.item) {
        if (
          this.product.logisticPalletItems > 0 &&
          this.item.quantity % this.product.logisticPalletItems == 0
        ) {
          return (
            this.item.quantity / this.product.logisticPalletItems + " pallet"
          );
        } else if (
          this.product.logisticPackagingItems > 0 &&
          this.item.quantity % this.product.logisticPackagingItems == 0
        ) {
          let unit = this.item.quantity / this.product.logisticPackagingItems;
          return "(" + unit + (unit == 1 ? " collo" : " colli") + ")";
        }
      }
      return "(0 colli)";
    },
    isPezzo: function() {
      return this.product?.productInfos?.TIPOLOGIA == "Pezzo";
    },
    acceptAlternative: {
      get() {
        return (
          get(this.item, "cartItemInfo.accept_alternatives", "false") == "true"
        );
      },
      set(value) {
        this.setCartItemInfo({
          items: this.item,
          name: "accept_alternatives",
          value: "" + value
        });
      }
    },
    // userNotes: {
    //   get() {
    //     return this.item.cartItemInfo.user_notes;
    //   },
    //   set(value) {
    //     this.tempUserNotes = value;
    //   }
    // },
    ...mapGetters({
      isMasterAccount: "cart/isMasterAccount",
      isBetaTester: "cart/isBetaTester"
    })
  },
  methods: {
    ...mapActions({ setCartItemInfo: "cart/setCartItemInfo" }),
    remove() {
      this.removeDialog = true;
    },
    doRemove() {
      this.removeDialog = false;
      this.removeItem(this.item);
    },
    async deleteSubscriptionPlan(subscriptionPlanId) {
      let params = {
        subscriptionPlanId: subscriptionPlanId
      };
      let result = await eurospinCustomService.deleteSubscriptionPlan(params);
      if (
        (result.response && result.response.status == 0) ||
        result.status == 0
      ) {
        global.EventBus.$emit(
          "deleteSubscriptionPlan",
          result.data.subscriptionPlans
        );
      }
    }
  }
};
</script>
