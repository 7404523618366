let routes = [
  {
    path: "/reset-password-request",
    name: "ResetPasswordRequest",
    meta: {
      breadCrumb: "Modifica Password",
      slug: "reset-password-request-b2b"
    },
    component: () => import("@/views/profile/ResetPasswordRequest.vue")
  },
  {
    path: "/reset-password-sent",
    name: "ResetPasswordSent",
    component: () => import("@/views/EbsnCategory.vue"),
    meta: {
      slug: "reset-password-sent"
    }
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () => import("@/views/profile/ResetPassword.vue"),
    meta: {
      slug: "reset-password-b2b"
    }
  },
  {
    path: "/reset-password-completed",
    name: "ResetPasswordCompleted",
    component: () => import("@/views/EbsnCategory.vue"),
    props: { showLogin: true },
    meta: {
      slug: "reset-password-completed"
    }
  },
  {
    path: "/abbonamenti",
    name: "Subscriptions",
    meta: {
      slug: "abbonamenti"
    },
    component: () => import("@/views/subscriptions/Subscriptions.vue")
  },
  {
    path: "/profile",
    meta: { requiresAuth: true },
    component: () => import("@/views/profile/Profile.vue"),
    children: [
      {
        path: "gifts",
        name: "ProfileGift",
        props: { showMenuNavigator: true },
        meta: {
          requiresAuth: true,
          breadCrumb: "Vantaggi",
          slug: ["profile/gifts", "gifts"]
        },
        component: () => import("@/views/profile/ProfileGift.vue")
      },
      {
        path: "profile-update",
        name: "ProfileUpdate",
        meta: {
          requiresAuth: true,
          breadCrumb: "Il mio profilo",
          slug: ["profile", "profile/profile-update"]
        },
        component: () => import("@/views/profile/ProfileUpdate.vue")
      },
      {
        path: "i-miei-abbonamenti",
        name: "MySubscriptions",
        props: { showMenuNavigator: true },
        meta: {
          requiresAuth: true,
          slug: "abbonamenti",
          breadCrumb: "I miei abbonamenti"
        },
        component: () => import("@/views/subscriptions/Subscriptions.vue")
      },
      {
        path: "profile-update-error",
        name: "ProfileUpdateError",
        meta: {
          requiresAuth: true,
          breadCrumb: "Il mio profilo",
          slug: ["profile-error", "profile/profile-update-error"]
        },
        component: () => import("@/views/profile/ProfileUpdateError.vue")
      },
      {
        path: "update-password",
        name: "UpdatePassword",
        meta: {
          requiresAuth: true,
          breadCrumb: "Aggiorna password",
          slug: ["update-password", "profile/update-password"]
        },
        component: () => import("@/views/profile/UpdatePassword.vue")
      },
      {
        path: "profile-confirmed",
        name: "ProfileConfirmed",
        component: () => import("@/views/profile/ProfileConfirmed.vue"),
        meta: {
          slug: ["profile-confirmed", "profile/profile-confirmed"]
        }
      },
      {
        path: "profile-updated",
        name: "ProfileUpdated",
        component: () => import("@/views/EbsnCategory.vue"),
        meta: {
          slug: ["profile-updated", "profile/profile-updated"]
        }
      },
      {
        path: "email-updated",
        name: "EmailUpdated",
        component: () => import("@/views/EbsnCategory.vue"),
        meta: {
          slug: ["email-updated", "profile/email-updated"]
        }
      },
      {
        path: "rewards-cart",
        name: "RewardsCart",
        meta: { requiresAuth: true, breadCrumb: "Carrello premi" },
        component: () => import("@/views/profile/RewardsCart.vue")
      },
      {
        path: "requested-rewards",
        name: "RequestedRewards",
        meta: { requiresAuth: true, breadCrumb: "Premi richiesti" },
        component: () => import("@/views/profile/RequestedRewards.vue")
      },
      {
        path: "billing-addresses",
        name: "BillingAddresses",
        meta: {
          requiresAuth: true,
          breadCrumb: "Indirizzi di fatturazione"
        },
        component: () => import("@/views/profile/BillingAddresses.vue")
      },
      {
        path: "create-offer",
        name: "CreateOffer",
        meta: { requiresAuth: true, breadCrumb: "Crea la tua offerta" },
        component: () => import("@/views/profile/RequestedRewards.vue")
      },
      {
        path: "orders",
        meta: {
          requiresAuth: true,
          breadCrumb: "I miei ordini"
        },
        props: { ordersAllUsers: false },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        // beforeEnter(to, from, next) {
        //   global.EventBus.$emit("resetFilters");
        //   next();
        // },
        children: [
          {
            name: "MyOrders",
            path: "",
            meta: {
              requiresAuth: true,
              slug: ["orders", "profile/orders"]
            },
            component: () => import("@/views/profile/Orders.vue")
          },
          {
            name: "Order",
            path: ":orderId",
            props: { ordersAllUsers: false },
            meta: {
              requiresAuth: true,
              breadCrumb: "Dettaglio ordine",
              slug: ["order", "profile/order"]
            },
            component: () => import("@/views/profile/Order.vue")
          }
        ]
      },
      {
        path: "all-orders",
        meta: {
          requiresAuth: true,
          breadCrumb: "Tutti gli ordini"
        },
        props: true,
        component: {
          render(c) {
            return c("router-view");
          }
        },
        // beforeEnter(to, from, next) {
        //   global.EventBus.$emit("resetFilters");
        //   next();
        // },
        children: [
          {
            name: "AllOrders",
            path: "",
            props: true,
            meta: {
              requiresAuth: true,
              slug: "orders"
            },
            component: () => import("@/views/profile/Orders.vue")
          },
          {
            name: "Order",
            path: ":orderId",
            props: { ordersAllUsers: true },
            meta: {
              requiresAuth: true,
              breadCrumb: "Dettaglio ordine",
              slug: "order"
            },
            component: () => import("@/views/profile/Order.vue")
          }
        ]
      },
      {
        path: "lists",
        meta: {
          requiresAuth: true,
          breadCrumb: "Le mie liste"
        },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        // beforeEnter(to, from, next) {
        //   global.EventBus.$emit("resetFilters");
        //   next();
        // },
        children: [
          {
            name: "Lists",
            path: "",
            meta: {
              requiresAuth: true,
              slug: ["lists", "profile/lists"]
            },
            component: () => import("@/views/profile/Lists.vue")
          },
          {
            path: ":listId",
            name: "List",
            meta: {
              requiresAuth: true,
              breadCrumb: "Dettaglio lista"
            },
            component: () => import("@/views/profile/Lists.vue")
          }
        ]
      },
      {
        path: "dashboard",
        meta: {
          requiresAuth: true,
          breadCrumb: "Panoramica"
        },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            name: "Dashboard",
            path: "",
            meta: {
              requiresAuth: true
            },
            component: () => import("@/views/profile/Dashboard.vue")
          }
        ]
      },
      {
        path: "addresses",
        meta: { requiresAuth: true, breadCrumb: "I miei indirizzi" },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "",
            name: "Addresses",
            meta: {
              requiresAuth: true,
              slug: ["addresses", "profile/addresses"]
            },
            component: () => import("@/views/profile/ProfileAddresses.vue")
          },
          {
            path: "manage/:addressId?",
            name: "EditAddress",
            props: { modal: true },
            meta: { requiresAuth: true, breadCrumb: "Dettaglio indirizzo" },
            component: () => import("@/views/profile/EditAddress.vue")
          }
        ]
      }
    ]
  },
  {
    path: "/ricevi-le-comunicazioni/thankyou",
    name: "ThankyouCommunication",
    meta: {
      slug: "thankyou"
    },
    props: route => ({
      communication: route.params.communication
    }),
    component: () => import("@/views/Thankyou.vue")
  },
  {
    path: "/contatti/thankyou",
    name: "ThankyouContactUs",
    meta: {
      slug: "thankyou"
    },
    props: route => ({
      communication: route.params.communication
    }),
    component: () => import("@/views/Thankyou.vue")
  }
];

export default routes;
